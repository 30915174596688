// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* stylelint-disable */
* {
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}
/* stylelint-enable */

html,
body {
  color: var(--textColor);
  font-family: 'Roboto', 'open sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}

body {
  font-size: 14px;
  line-height: 1.528571429; /* 20/14 */
}

/* Override normalize */

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-size: inherit;
  font-family: inherit;
  line-height: 1.528571429; /* 20/14 */
}

/* Better defaults for unordererd lists */

ul {
  margin: 0;
  padding-left: 20px;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) {
  input,
  optgroup,
  select,
  textarea {
    font-size: 16px;
  }
}
`, "",{"version":3,"sources":["webpack://./frontend/src/Styles/scaffolding.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;EACE,sBAAsB;AACxB;;AAEA;;EAEE,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;AACA,qBAAqB;;AAErB;;EAEE,uBAAuB;EACvB,sFAAsF;AACxF;;AAEA;EACE,eAAe;EACf,wBAAwB,EAAE,UAAU;AACtC;;AAEA,uBAAuB;;AAEvB;;;;;EAKE,SAAS;EACT,kBAAkB;EAClB,oBAAoB;EACpB,wBAAwB,EAAE,UAAU;AACtC;;AAEA,yCAAyC;;AAEzC;EACE,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE;;;;IAIE,eAAe;EACjB;AACF","sourcesContent":["/* stylelint-disable */\r\n* {\r\n  box-sizing: border-box;\r\n}\r\n\r\n*::before,\r\n*::after {\r\n  box-sizing: border-box;\r\n}\r\n\r\n*:focus {\r\n  outline: none;\r\n}\r\n/* stylelint-enable */\r\n\r\nhtml,\r\nbody {\r\n  color: var(--textColor);\r\n  font-family: 'Roboto', 'open sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;\r\n}\r\n\r\nbody {\r\n  font-size: 14px;\r\n  line-height: 1.528571429; /* 20/14 */\r\n}\r\n\r\n/* Override normalize */\r\n\r\nbutton,\r\ninput,\r\noptgroup,\r\nselect,\r\ntextarea {\r\n  margin: 0;\r\n  font-size: inherit;\r\n  font-family: inherit;\r\n  line-height: 1.528571429; /* 20/14 */\r\n}\r\n\r\n/* Better defaults for unordererd lists */\r\n\r\nul {\r\n  margin: 0;\r\n  padding-left: 20px;\r\n}\r\n\r\n@media only screen and (min-device-width: 375px) and (max-device-width: 812px) {\r\n  input,\r\n  optgroup,\r\n  select,\r\n  textarea {\r\n    font-size: 16px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
